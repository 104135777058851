import React from "react";
import { format } from "date-fns";
import get from "lodash/get";
import uniq from "lodash/uniq";
import isArray from "lodash/isArray";
import indexOf from "lodash/indexOf";
import isEmpty from "lodash/isEmpty";
import maxBy from "lodash/maxBy";

var CollectionStateContext = React.createContext();
var CollectionDispatchContext = React.createContext();

const initialState = {
  creatingDialogFromVideoColDialog: false,
  createdColID: false,
  selected: [], // used for collection
  videoSelected: {},
  collections: [
    // {
    //   id: 0,
    //   name: "一年級國文課",
    //   created_date: "2020-07-01",
    //   videos: [0, 1, 2],
    // },
    // {
    //   id: 1,
    //   name: "二年級國文課",
    //   created_date: "2020-08-01",
    //   videos: [],
    // },
    // {
    //   id: 2,
    //   name: "三年級國文課",
    //   created_date: "2020-09-01",
    //   videos: [1, 2, 3],
    // },
    // {
    //   id: 3,
    //   name: "一年級國文課2",
    //   created_date: "2020-07-01",
    //   videos: [3, 4, 5],
    // },
    // {
    //   id: 4,
    //   name: "二年級國文課2",
    //   created_date: "2020-08-01",
    //   videos: [],
    // },
    // {
    //   id: 5,
    //   name: "三年級國文課2",
    //   created_date: "2020-09-01",
    //   videos: [0, 4],
    // },
    // {
    //   id: 6,
    //   name: "一年級國文課3",
    //   created_date: "2020-07-01",
    //   videos: [0, 1, 5],
    // },
    // {
    //   id: 7,
    //   name: "二年級國文課3",
    //   created_date: "2020-08-01",
    //   videos: [5, 1, 2],
    // },
    // {
    //   id: 8,
    //   name: "三年級國文課3",
    //   created_date: "2020-09-01",
    //   videos: [2, 3],
    // },
  ],
};

function collectionReducer (state, action) {
  const { type, payload = {} } = action;
  const {
    selected,
    videoSelected,
    collections,
    creatingDialogFromVideoColDialog,
    newCollectionId,
  } = state;

  const { cid, vid, name, videos } = payload;
  console.log("payload: ", payload);
  const origin = get(videoSelected, cid, []);

  switch (type) {
    case "ADD_COLLECTION":
      const newId = isEmpty(collections) ? 0 : maxBy(collections, "id").id + 1;
      return {
        ...state,
        createdColID: creatingDialogFromVideoColDialog ? newId : false,
        collections: [
          ...state.collections,
          {
            id: newId,
            name,
            videos: [],
            created_date: format(new Date(), "yyyy-MM-dd"),
          },
        ],
      };
    case "REMOVE_COLLECTION":
      return {
        ...state,
        collections: state.collections.filter(
          collection => collection.id !== cid,
        ),
      };
    case "BULK_REMOVE_COLLECTION":
      return {
        selected: [],
        collections: collections.filter(
          collection => selected.indexOf(collection.id) === -1,
        ),
      };

    case "COPY_COLLECTION":
      return {
        ...state,
        collections: [
          ...collections,
          {
            id: isEmpty(collections) ? 0 : maxBy(collections, "id").id + 1,
            name,
            videos,
            created_date: format(new Date(), "yyyy-MM-dd"),
          },
        ],
      };

    case "RENAME_COLLECTION":
      return {
        ...state,
        collections: collections.map(collection =>
          collection.id === cid ? { ...collection, name } : collection,
        ),
      };
    case "SELECT_COLLECTION":
      return {
        ...state,
        selected: [...state.selected, payload.id],
      };
    case "UNSELECT_COLLECTION":
      return {
        ...state,
        selected: state.selected.filter(id => id !== payload.id),
      };
    case "UNSELECT_ALL_COLLECTION":
      return {
        ...state,
        selected: [],
      };

    case "SELECT_COLLECTION_VIDEO":
      return {
        ...state,
        videoSelected: {
          ...videoSelected,
          [cid]: uniq([...origin, vid]),
        },
      };
    case "UNSELECT_COLLECTION_VIDEO":
      return {
        ...state,
        videoSelected: {
          ...videoSelected,
          [cid]: origin.filter(e => e !== vid),
        },
      };

    case "SAVE_VIDEO_COLLECTIONS":
      console.log(collections, cid);
      return {
        ...state,
        collections: collections.map(collection =>
          isArray(cid) && indexOf(cid, collection.id) !== -1
            ? {
                ...collection,
                videos: uniq([...collection.videos, vid]),
              }
            : {
                ...collection,
                videos: collection.videos.filter(e => e !== vid),
              },
        ),
      };

    case "REMOVE_COLLECTION_VIDEO":
      return {
        ...state,
        collections: collections.map(collection =>
          collection.id === cid
            ? {
                ...collection,
                videos: collection.videos.filter(e => e !== vid),
              }
            : collection,
        ),
      };

    case "BULK_REMOVE_COLLECTION_VIDEO":
      return {
        ...state,
        videoSelected: {
          ...videoSelected,
          [cid]: [],
        },
        collections: collections.map(collection =>
          collection.id === cid
            ? {
                ...collection,
                videos: collection.videos.filter(
                  e => indexOf(videoSelected[cid], e) === -1,
                ),
              }
            : collection,
        ),
      };

    case "CREATE_COLLECTION_DOING":
      return {
        ...state,
        creatingDialogFromVideoColDialog: true,
      };

    case "CREATE_COLLECTION_DONE":
      return {
        ...state,
        creatingDialogFromVideoColDialog: false,
      };

    case "SET_NEW_CREATED_COLLECTION_ID":
      return {
        ...state,
        createdColID: creatingDialogFromVideoColDialog
          ? newCollectionId
          : false,
      };
    case "RESET_DIALOG_COLLECTION_ID":
      return {
        ...state,
        createdColID: false,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CollectionProvider ({ children }) {
  // https://medium.com/@walkccc/build-a-todo-list-app-with-react-hooks-and-context-a7f8e9f158af
  var [state, dispatch] = React.useReducer(collectionReducer, initialState);
  return (
    <CollectionStateContext.Provider value={state}>
      <CollectionDispatchContext.Provider value={dispatch}>
        {children}
      </CollectionDispatchContext.Provider>
    </CollectionStateContext.Provider>
  );
}

function useCollectionState () {
  const context = React.useContext(CollectionStateContext);
  if (context === undefined) {
    throw new Error(
      "useCollectionState must be used within a CollectionProvider",
    );
  }
  return context;
}

function useCollectionDispatch () {
  const context = React.useContext(CollectionDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useCollectionDispatch must be used within a CollectionProvider",
    );
  }
  return context;
}

export { CollectionProvider, useCollectionState, useCollectionDispatch };
