import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";
import sentry from "./utils/sentry";
import tracker from "./utils/tracker";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { VideoProvider } from "./context/VideoContext";
import { CollectionProvider } from "./context/CollectionContext";
import { WatchHistoryProvider } from "./context/WatchHistoryContext";
import { TempProvider } from "./context/TempContext";
import { OtherProvider } from "./context/OtherContext";

sentry.start();
const instance = tracker.create();

const container = document.getElementById("root");

ReactDOM.render(
  <LayoutProvider>
    <MatomoProvider value={instance}>
      <UserProvider>
        <CollectionProvider>
          <VideoProvider>
            <WatchHistoryProvider>
              <ThemeProvider theme={Themes.default}>
                <TempProvider>
                  <OtherProvider>
                    <SnackbarProvider maxSnack={3}>
                      <CssBaseline />
                      <App />
                    </SnackbarProvider>
                  </OtherProvider>
                </TempProvider>
              </ThemeProvider>
            </WatchHistoryProvider>
          </VideoProvider>
        </CollectionProvider>
      </UserProvider>
    </MatomoProvider>
  </LayoutProvider>,
  container,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
