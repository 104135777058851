import React from "react";

import tracker from "../utils/tracker";

var VideoStateContext = React.createContext();
var VideoDispatchContext = React.createContext();

function videoReducer (state, action) {
  switch (action.type) {
    case "UPDATE_VOICE_TRACK_SUCCESS":
      return {
        ...state,
        url: action.payload.src,
        trackIndex: action.payload.trackIndex,
      };
    case "PLAY_MOVIE_FAILURE":
      return { ...state, hasQuota: false };
    case "PLAYER_RELOAD":
      return { ...state, playId: state.playId + 1, resetting: true };
    case "PLAYER_RELOAD_SUCCESS":
      return { ...state, resetting: false };
    case "SET_QUOTA_EXCEED":
      return { ...state, hasQuota: false };
    case "SET_QUOTA_ENOUGH":
      return { ...state, hasQuota: true };
    case "SET_UNKNOWN_ERROR":
      return { ...state, error: true };
    case "SET_CONTROL_SHOW":
      return { ...state, showControl: true };
    case "SET_CONTROL_HIDDEN":
      return { ...state, showControl: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function VideoProvider ({ children }) {
  var [state, dispatch] = React.useReducer(videoReducer, {
    videoType: "trailer",
    url: "",
    forceUrl: null,
    playId: 0,
    resetting: false,
    hasQuota: true,
    error: true,
    showControl: false,
    play: [],
    trackIndex: 0,
  });

  return (
    <VideoStateContext.Provider value={state}>
      <VideoDispatchContext.Provider value={dispatch}>
        {children}
      </VideoDispatchContext.Provider>
    </VideoStateContext.Provider>
  );
}

function useVideoState () {
  var context = React.useContext(VideoStateContext);
  if (context === undefined) {
    throw new Error("useVideoState must be used within a VideoProvider");
  }
  return context;
}

function useVideoDispatch () {
  var context = React.useContext(VideoDispatchContext);
  if (context === undefined) {
    throw new Error("useVideoDispatch must be used within a VideoProvider");
  }
  return context;
}

export {
  VideoProvider,
  useVideoState,
  useVideoDispatch,
  handleMaskTopButton,
  handleMaskBottomButton,
  handlePlayMovie,
  handlePlayTrailer,
};

// ###########################################################

function handleMaskTopButton (
  dispatch,
  player,
  isMovie,
  reserveQuota,
  setOpenDialog,
  videoId,
  isEnd,
  history,
  noTrailer,
  trackEvent,
) {
  try {
    if (isMovie) {
      reserveQuota(videoId).then(data => {
        if ((data.msg === "ok" && data.token) || data.has_quota === true) {
          player.play();
        } else {
          setOpenDialog(true);
          tracker.recordNoQuota({
            trackEvent,
            videoId,
            videoType: isMovie ? "movie" : "trailer",
            quotaCnt: data.quota_cnt,
          });
          return;
        }
      });
    } else {
      if (isEnd || noTrailer) {
        reserveQuota(videoId).then(data => {
          if ((data.msg === "ok" && data.token) || data.has_quota === true) {
            history.push(`/video/detail/movie/${videoId}`);
          } else {
            setOpenDialog(true);
            tracker.recordNoQuota({
              trackEvent,
              videoId,
              videoType: isMovie ? "movie" : "trailer",
              quotaCnt: data.quota_cnt,
            });
            return;
          }
        });
      } else {
        player.play();
      }
    }
  } catch (e) {
    console.log("play error", e);
  }
}

async function handleMaskBottomButton (
  dispatch,
  isLogin,
  videoId,
  isTrailer,
  history,
  reserveQuota,
  setOpenDialog,
  isEnd,
  player,
  trackEvent,
) {
  if (isLogin) {
    if (isTrailer) {
      if (isEnd) {
        player.play();
      } else {
        reserveQuota(videoId)
          .then(data => {
            if ((data.msg === "ok" && data.token) || data.has_quota === true) {
              history.push(`/video/detail/movie/${videoId}`);
            } else {
              setOpenDialog(true);
              tracker.recordNoQuota({
                trackEvent,
                videoId,
                videoType: !isTrailer ? "movie" : "trailer",
                quotaCnt: data.quota_cnt,
              });
            }
          })
          .catch(err => console.log(err));
      }
    } else {
      history.push(`/video/detail/trailer/${videoId}`);
    }
  }
}

function handlePlayMovie ({
  isLogin,
  isTrailer,
  videoId,
  history,
  reserveQuota,
  setOpenLoginDialog,
  setOpenQuotaDialog,
  player,
  trackEvent,
}) {
  if (!isLogin) {
    setOpenLoginDialog(true);
    return;
  }

  reserveQuota(videoId)
    .then(data => {
      if ((data.msg === "ok" && data.token) || data.has_quota === true) {
        if (isTrailer) {
          history.push(`/video/detail/movie/${videoId}`);
        } else {
          player && player.play();
        }
      } else {
        setOpenQuotaDialog(true);
        tracker.recordNoQuota({
          trackEvent,
          videoId,
          videoType: isTrailer ? "trailer" : "movie",
          quotaCnt: data.quota_cnt,
        });
      }
    })
    .catch(err => console.log(err));
}

function handlePlayTrailer ({
  isLogin,
  isTrailer,
  videoId,
  history,
  reserveQuota,
  setOpenLoginDialog,
  setOpenQuotaDialog,
  player,
  trackEvent,
}) {
  if (!isLogin) {
    player && player.play();
    return;
  }

  reserveQuota(videoId)
    .then(data => {
      if ((data.msg === "ok" && data.token) || data.has_quota === true) {
        if (!isTrailer) {
          history.push(`/video/detail/trailer/${videoId}`);
        } else {
          player && player.play();
        }
      } else {
        setOpenQuotaDialog(true);
        tracker.recordNoQuota({
          trackEvent,
          videoId,
          videoType: isTrailer ? "trailer" : "movie",
          quotaCnt: data.quota_cnt,
        });
      }
    })
    .catch(err => console.log(err));
}
