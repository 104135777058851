import React from "react";

var OtherStateContext = React.createContext();
var OtherDispatchContext = React.createContext();

function otherReducer (state, action) {
  switch (action.type) {
    case "SET_CONTROL_SHOW":
      return { ...state, showControl: true };
    case "SET_CONTROL_HIDDEN":
      return { ...state, showControl: false };
    case "SET_FULLSCREEN_ACTIVE":
      return { ...state, isFullscreenActive: true };
    case "SET_FULLSCREEN_INACTIVE":
      return { ...state, isFullscreenActive: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function OtherProvider ({ children }) {
  var [state, dispatch] = React.useReducer(otherReducer, {
    showControl: false,
    isFullscreenActive: false,
  });

  return (
    <OtherStateContext.Provider value={state}>
      <OtherDispatchContext.Provider value={dispatch}>
        {children}
      </OtherDispatchContext.Provider>
    </OtherStateContext.Provider>
  );
}

function useOtherState () {
  var context = React.useContext(OtherStateContext);
  if (context === undefined) {
    throw new Error("useOtherState must be used within a OtherProvider");
  }
  return context;
}

function useOtherDispatch () {
  var context = React.useContext(OtherDispatchContext);
  if (context === undefined) {
    throw new Error("useOtherDispatch must be used within a OtherProvider");
  }
  return context;
}

export { OtherProvider, useOtherState, useOtherDispatch };

// ###########################################################
