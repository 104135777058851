import axios from "./axios";

import { apiUrl, staticUrl } from "./utils/const";
import { getTabID, getToken } from "./utils/common";
import { getBaseUrl } from "./axios";

const timeout = 15000;

const getOption = () => {
  const token = getToken() || "";
  return {
    timeout: timeout,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const fetcher = url => axios.get(url).then(res => res.data);
const fetcherWithToken = url =>
  axios.get(url, getOption()).then(res => res.data);
const posterWithToken = url =>
  axios.post(url, getOption()).then(res => res.data);

const getPlatformInfo = () => {
  return axios({
    method: "get",
    url: `/api/v1/platforms/`,
  }).then(res => res.data);
};

const resetPassword = ({ token, newPassword }) => {
  return axios.post(`/api/v1/reset-password`, {
    token,
    new_password: newPassword,
  });
};

const recoveryPassword = email => {
  const token = getToken();
  if (token) {
    return axios({
      method: "post",
      url: `/api/v1/users/password-recovery/${email}`,
    });
  }
  return axios({
    method: "post",
    url: `/api/v1/password-recovery/${email}`,
  });
};

const createUserWatchHistory = (videoId, videoType) => {
  const token = getToken();
  if (!token) {
    return Promise.resolve();
  }
  return axios({
    method: "post",
    url: `/api/v1/users/watched`,
    data: {
      video_id: videoId,
      video_type: videoType,
    },
  }).then(res => res.data);
};

const getVideoInfo = videoId => {
  // must keep this header or got 401 error
  const option = getOption();
  const apiUrl = getBaseUrl();
  return axios({
    ...option,
    method: "get",
    url: `${apiUrl}/api/v1/videos/detail/${videoId}`,
  }).then(res => res.data);
};

const createCollection = name => {
  return axios({
    method: "post",
    url: `/api/v1/users/collection`,
    data: {
      name,
    },
  }).then(res => res.data);
};

const updateCollection = (id, name, videoIds = []) => {
  return axios({
    method: "put",
    url: `/api/v1/users/collection/${id}`,
    data: {
      name,
    },
  }).then(res => res.data);
};

const copyCollection = (id, name) => {
  return axios({
    method: "post",
    url: `/api/v1/users/copy_collection`,
    data: {
      id,
      name,
    },
  }).then(res => res.data);
};

const deleteCollection = id => {
  return axios({
    method: "delete",
    url: `/api/v1/users/collection/${id}`,
  }).then(res => res.data);
};

const bulkDeleteCollection = ids => {
  return axios({
    method: "delete",
    url: `/api/v1/users/bulk/collection`,
    data: {
      ids,
    },
  }).then(res => res.data);
};

const updateCollectionVideos = ({
  collection_id,
  expected_ids,
  add_ids,
  delete_ids,
}) => {
  return axios({
    method: "post",
    url: `/api/v1/users/collection/videos`,
    data: {
      collection_id,
      expected_ids,
      add_ids,
      delete_ids,
    },
  }).then(res => res.data);
};

const updateVideoCollections = ({
  video_id,
  expected_ids,
  add_ids,
  delete_ids,
}) => {
  return axios({
    method: "post",
    url: `/api/v1/users/video/collections`,
    data: {
      video_id,
      expected_ids,
      add_ids,
      delete_ids,
    },
  }).then(res => res.data);
};

const reserveQuota = videoId => {
  if (!getToken()) {
    return Promise.reject("empty token");
  }
  const tabId = getTabID();
  return axios({
    method: "post",
    url: `/api/v1/videos/quota`,
    params: {
      vid: videoId,
      tid: tabId,
    },
  }).then(res => res.data);
};

const releaseQuota = () => {
  if (!getToken()) {
    return;
  }
  const tabId = getTabID();
  return axios({
    method: "post",
    url: `/api/v1/videos/release_quota`,
    params: {
      tid: tabId,
    },
  }).then(res => res.data);
};

const releaseQuotaByBeacon = tabId => {
  const token = getToken();
  if (!token) {
    return;
  }
  var data = { tid: tabId, token };
  var text = JSON.stringify(data);

  navigator.sendBeacon(`/api/v1/videos/beacon/realese-quota`, text);
};

const sendQuotaHeartBeat = videoId => {
  const tabId = getTabID();
  return axios({
    method: "post",
    url: `/api/v1/videos/heartbeat`,
    params: {
      vid: videoId,
      tid: tabId,
    },
  }).then(res => res.data);
};

const checkUserValid = name => {
  return axios({
    method: "post",
    url: `/api/v1/users/validate`,
    data: { name },
  }).then(res => res.data);
};

const createUser = payload => {
  return axios({
    method: "post",
    url: `/api/v1/users/`,
    data: payload,
  });
};

const renewSubscribe = planId => {
  return axios({
    method: "post",
    url: `/api/v1/users/user/renew/${planId}`,
  });
};

const getUserInfo = () => {
  const option = getOption();
  if (option.headers.Authorization) {
    return axios.get(`/api/v1/users/me`).then(res => res.data);
  } else {
    throw new Error("without valid token");
  }
};

const updateUserInfo = ({
  real_name,
  org_name,
  email,
  mobile,
  tel,
  birthday,
}) => {
  return axios({
    method: "put",
    url: `/api/v1/users/me`,
    data: {
      real_name,
      org_name,
      email,
      mobile,
      tel,
      birthday,
    },
  }).then(res => res.data);
};

const downloadAttachment = id => {
  return axios({
    method: "get",
    url: `/api/v1/videos/attachment/${id}`,
    responseType: "blob",
  }).then(res => {
    const type = res.headers["content-type"];
    const contentDisposition = res.headers["content-disposition"] || "filename";

    const filenamePart = decodeURI(contentDisposition.split("filename")[1]);
    const pattern1 = /^\*=utf-8''/g;
    const pattern2 = /^="/g;
    let filename = "未命名附件";
    if (pattern1.test(filenamePart)) {
      filename = filenamePart.replaceAll(pattern1, "");
    } else if (pattern2.test(filenamePart)) {
      filename = filenamePart.replaceAll(pattern2, "").replaceAll(/\"/g, "");
    }
    const blob = new Blob([res.data], {
      type: type,
      encoding: "UTF-8",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    link.click();
    window.URL.revokeObjectURL(link);
  });
};

export {
  apiUrl,
  staticUrl,
  timeout,
  axios,
  fetcher,
  fetcherWithToken,
  posterWithToken,
  getPlatformInfo,
  resetPassword,
  recoveryPassword,
  createUserWatchHistory,
  getVideoInfo,
  createCollection,
  updateCollection,
  deleteCollection,
  copyCollection,
  bulkDeleteCollection,
  updateCollectionVideos,
  updateVideoCollections,
  reserveQuota,
  releaseQuota,
  releaseQuotaByBeacon,
  sendQuotaHeartBeat,
  checkUserValid,
  createUser,
  renewSubscribe,
  getUserInfo,
  updateUserInfo,
  downloadAttachment,
};
