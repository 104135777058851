import defaultThemeConfig from "./default";

import { createMuiTheme } from '@material-ui/core/styles'

const defaultTheme = createMuiTheme(defaultThemeConfig);
const overrides = {
  typography: {
    fontSize: 16,
    fontFamily: [
      '"Noto Sans TC"',
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "2rem",
      fontWeight: defaultTheme.typography.fontWeightMedium,
      lineHeight: 1.5,
      color: "#212121",
      [defaultTheme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
      },
    },
    h2: {
      fontSize: "1.75rem",
      lineHeight: 1.14,
      letterSpacing: 2,
      fontWeight: defaultTheme.typography.fontWeightBold,
      [defaultTheme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
        lineHeight: 1.11,
      },
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: defaultTheme.typography.fontWeightBold,
      lineHeight: 1.5,
      [defaultTheme.breakpoints.down("xs")]: {
        fontSize: "1rem",
        fontWeight: defaultTheme.typography.fontWeightMedium,
      },
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: defaultTheme.typography.fontWeightMedium,
      lineHeight: 1.33,
      [defaultTheme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
        lineHeight: 1.43,
      },
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: defaultTheme.typography.fontWeightRegular,
      lineHeight: 1.71,
      [defaultTheme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        lineHeight: 1.5,
      },
    },
    h6: {
      fontSize: "0.875rem",
      fontWeight: defaultTheme.typography.fontWeightMedium,
      lineHeight: 1.71,
      [defaultTheme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        lineHeight: 1.33,
      },
    },
    h7: {
      fontSize: "0.75rem",
      fontWeight: defaultTheme.typography.fontWeightRegular,
      lineHeight: 1.33,
    },
    tags: {
      fontSize: "1.125rem",
      lineHeight: 1.11,
      letterSpacing: 1,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
    },
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: 0.15,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.71,
      letterSpacing: 0.1,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
      },
    },
    description: {
      fontSize: "1rem",
      lineHeifht: 2,
      letterSpacing: 0.15,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: 1.71,
      letterSpacing: 0.15,
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 700,
    },
    smallCaption: {
      fontSize: "0.75rem",
      lineHeight: 1.67,
    },
    font24: {
      fontSize: "1.5rem",
      lineHeight: 2.33,
    },
    inputLabel: {
      fontSize: "0.75rem",
      lineHeight: 1.67,
    },
    inputcBox: {
      fontSize: "1rem",
      fontWeight: 500,
      letterSpacing: 0.1,
      // Disable iOS input zoom (CSS only)
      // https://codepen.io/jakob-e/pen/yakBwJ
      transform: "scale(0.875)", // 14px
      [defaultTheme.breakpoints.down("xs")]: {
        transform: "scale(0.75)", // 12px
      },
    }
  },
};

const theme = createMuiTheme({ ...defaultThemeConfig, ...overrides });
export default { default: theme };
