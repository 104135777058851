import React from "react";

import {
  getToken,
  setToken,
  resetToken,
  resetPreferQuality,
  getPreviousPage,
  enableFirstEnterIPAutoLogin,
  disableFirstEnterIPAutoLogin,
} from "../utils/common";
import tracker from "../utils/tracker";
import { axios, getUserInfo } from "../clientAPI";
import { getBaseUrl } from "../axios";

// async: https://kentcdodds.com/blog/how-to-use-react-context-effectively

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer (state, action) {
  const { type, payload = {} } = action;
  switch (type) {
    case "LOGIN_SUCCESS":
      enableFirstEnterIPAutoLogin();
      return {
        ...state,
        isAuthenticated: true,
        loginError: false,
        loginErrorCnt: 0,
      };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, loginError: false };
    case "LOGIN_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        loginError: true,
        loginErrorCnt: state.loginErrorCnt + 1,
        inactive: payload.inactive === true,
        invalidIP: payload.invalidIP === true,
        IPGroups: payload.IPGroups,
        notFound: payload.notFound === true,
      };
    case "SELECT_SUBSCRIBE_PLAN":
      return {
        ...state,
        planId: payload.planId,
        planTitle: payload.planTitle,
        newOld: payload.newOld,
      };
    case "SET_USER_INFO":
      return { ...state, userInfo: payload };
    case "REMOVE_USER_INFO":
      return { ...state, userInfo: {} };
    case "SET_LOGIN_URL":
      return { ...state, loginUrl: payload.loginUrl };
    case "SET_PLATFORM_INFO":
      return { ...state, platform: payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider ({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!getToken(),
    token: getToken() || "",
    loginError: false,
    loginErrorCnt: 0,
    inactive: false,
    invalidIP: false,
    notFound: false,
    planId: 0,
    planTitle: "",
    newOld: "new",
    loginUrl: "",
    userInfo: localStorage.getItem("user_info")
      ? JSON.parse(localStorage.getItem("user_info"))
      : {},
    platform: {},
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState () {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch () {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  loginParterUser,
  loginAgentUser,
  loginIPUser,
  loginSSOUser,
  signOut,
  resetUser,
};

// ###########################################################

const getLoginErrMsg = error => ({
  inactive: error?.response?.data?.detail === "Inactive user",
  invalidIP: error?.response?.data?.detail.includes("Invalid IP"),
  IPGroups: /\d+\.\d+\.\d+\.\d+/.exec(error?.response?.data?.detail) || [],
  notFound: error?.response?.data?.detail.includes(
    "Incorrect account name or password",
  ),
});

function resetUser (dispatch, pushInstruction) {
  resetToken();
  localStorage.removeItem("user_info");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  dispatch({ type: "REMOVE_USER_INFO" });
  tracker.resetSession(pushInstruction);
}

function signOut (dispatch, history, pushInstruction) {
  resetUser(dispatch, pushInstruction);
  resetPreferQuality("preferQuality");
  disableFirstEnterIPAutoLogin();
  history.push("/");
}

function redirect (history) {
  const path = getPreviousPage() || "/";
  history.push(path);
}

function loginUser (
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  trackEvent,
  pushInstruction,
) {
  setIsLoading(true);

  const params = new URLSearchParams();
  params.append("username", login);
  params.append("password", password);

  return axios
    .post(`/api/v1/login/access-token`, params)
    .then(function (response) {
      setIsLoading(false);
      const token = response.data.access_token;
      if (token) {
        setToken(token);
        dispatch({ type: "LOGIN_SUCCESS" });
        return axios
          .get(`/api/v1/users/me`)
          .then(response => {
            const data = response.data;
            localStorage.setItem("user_info", JSON.stringify(data));
            dispatch({ type: "SET_USER_INFO", payload: data });
            tracker.setUserDetail({
              pushInstruction,
              userId: data.id,
              userName: data.name,
              userType: data.user_type,
            });
            tracker.login({ userId: data.id, trackEvent, pushInstruction });
            redirect(history);
            return Promise.resolve(true);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        dispatch({ type: "LOGIN_FAILURE" });
        setIsLoading(false);
      }
    })
    .catch(function (error) {
      dispatch({
        type: "LOGIN_FAILURE",
        payload: getLoginErrMsg(error),
      });
      setIsLoading(false);
    });
}

function loginParterUser (
  dispatch,
  authToken,
  history,
  trackEvent,
  pushInstruction,
) {
  if (authToken) {
    setToken(authToken);
    getUserInfo()
      .then(data => {
        dispatch({ type: "LOGIN_SUCCESS" });
        dispatch({
          type: "SET_LOGIN_URL",
          payload: { loginUrl: data?.platform?.login_url },
        });
        dispatch({ type: "SET_USER_INFO", payload: data });
        tracker.setUserDetail({
          pushInstruction,
          userId: data.id,
          userName: data.name,
          userType: data.user_type,
        });
        tracker.partnerLogin({
          trackEvent,
          pushInstruction,
          method: "token",
          userId: data.id,
        });
        redirect(history);
      })
      .catch(error => {
        setToken(null);
        dispatch({ type: "LOGIN_FAILURE" });
        console.log(error);
        alert("驗證失敗");
      });
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
  }
}

function loginAgentUser (dispatch, username, password, history, setIsLoading) {
  setIsLoading(true);

  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", password);

  return axios
    .post(`/api/v1/partner/login/agent`, params)
    .then(function (response) {
      setIsLoading(false);
      const url = response.data.url;
      if (url) {
        history.push(url);
      } else {
        dispatch({ type: "LOGIN_FAILURE" });
        setIsLoading(false);
      }
    })
    .catch(function (error) {
      console.log(error);
      dispatch({ type: "LOGIN_FAILURE" });
      setIsLoading(false);
    });
}

function loginIPUser ({
  dispatch,
  setIsLoading,
  trackEvent,
  pushInstruction,
  setIPLoginError,
  history,
}) {
  setIsLoading(true);
  const apiUrl = getBaseUrl();
  return axios
    .post(`${apiUrl}/api/v1/partner/login/ip/access-token`, {
      headers: {
        "original-origin": window.location.origin,
      },
    })
    .then(function (response) {
      const token = response.data.access_token;
      if (token) {
        setToken(token);
        dispatch({ type: "LOGIN_SUCCESS" });
        return axios
          .get(`/api/v1/users/me`)
          .then(response => {
            const data = response.data;
            localStorage.setItem("user_info", JSON.stringify(data));
            dispatch({ type: "SET_USER_INFO", payload: data });
            tracker.setUserDetail({
              pushInstruction,
              userId: data.id,
              userName: data.name,
              userType: data.user_type,
            });
            tracker.partnerLogin({
              userId: data.id,
              trackEvent,
              pushInstruction,
              method: "ip",
            });
            redirect(history);
            return Promise.resolve(true);
          })
          .catch(error => {
            console.log(error);
            setIsLoading(false);
          });
      } else {
        dispatch({ type: "LOGIN_FAILURE" });
        setIsLoading(false);
        setIPLoginError(true);
      }
    })
    .catch(function (error) {
      dispatch({
        type: "LOGIN_FAILURE",
        payload: getLoginErrMsg(error),
      });
      setIsLoading(false);
      setIPLoginError(true);
    });
}

function loginSSOUser ({ dispatch, setIsLoading, url, history }) {
  setIsLoading(true);
  return axios
    .post(`/api/v1/partner/login/sso`, { url })
    .then(function (response) {
      setIsLoading(false);
      const url = response.data.url;
      if (url) {
        history.push(url);
      } else {
        dispatch({ type: "LOGIN_FAILURE" });
        setIsLoading(false);
      }
    })
    .catch(function (error) {
      console.log(error);
      dispatch({ type: "LOGIN_FAILURE" });
      setIsLoading(false);
    });
}
