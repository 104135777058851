import React from "react";

var TempStateContext = React.createContext();
var TempDispatchContext = React.createContext();

function tempReducer (state, action) {
  const { payload = {} } = action;
  switch (action.type) {
    case "SET_HOMEPAGE_PAYLOAD":
      return { ...state, ...payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TempProvider ({ children }) {
  var [state, dispatch] = React.useReducer(tempReducer, {
    menus: [],
    topics: [],
    banners: [],
  });
  return (
    <TempStateContext.Provider value={state}>
      <TempDispatchContext.Provider value={dispatch}>
        {children}
      </TempDispatchContext.Provider>
    </TempStateContext.Provider>
  );
}

function useTempState () {
  var context = React.useContext(TempStateContext);
  if (context === undefined) {
    throw new Error("useTempState must be used within a TempProvider");
  }
  return context;
}

function useTempDispatch () {
  var context = React.useContext(TempDispatchContext);
  if (context === undefined) {
    throw new Error("useTempDispatch must be used within a TempProvider");
  }
  return context;
}

export { TempProvider, useTempState, useTempDispatch };
