import { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useUserDispatch, signOut, resetUser } from "../context/UserContext";

import { getToken } from "../utils/common";
import { getUserInfo } from "../clientAPI";

function UserInfo () {
  const history = useHistory();
  const token = getToken();
  const userDispatch = useUserDispatch();
  const { pushInstruction } = useMatomo();

  useEffect(() => {
    if (token) {
      getUserInfo()
        .then(res => {
          localStorage.setItem("user_info", JSON.stringify(res));
          userDispatch({ type: "SET_USER_INFO", payload: res });
          pushInstruction("setUserId", res.id);
        })
        .catch(err => {
          console.log(err);
          signOut(userDispatch, history, pushInstruction);
        });
    } else {
      resetUser(userDispatch, pushInstruction);
    }
  }, []);

  return null;
}

export default UserInfo;
