import React from "react";
import { format } from "date-fns";
import isEmpty from "lodash/isEmpty";
import maxBy from "lodash/maxBy";

var WatchHistoryStateContext = React.createContext();
var WatchHistoryDispatchContext = React.createContext();

const initialState = [];

function watchHistoryReducer (state, action) {
  const { type, payload = {} } = action;
  const { id } = payload;
  const dt = new Date()

  switch (type) {
    case "ADD_WATCH_VIDEO":
      return [
        ...state,
        {
          id: isEmpty(state) ? 0 : maxBy(state, "id") + 1,
          key: `${id}_${format(dt, "yyyy-MM-dd")}`,
          vid: id,
          date: format(dt, "yyyy-MM-dd"),
          created_at: format(dt, "yyyy-MM-dd HH:MM:SS"),
        },
      ];

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function WatchHistoryProvider ({ children }) {
  // https://medium.com/@walkccc/build-a-todo-list-app-with-react-hooks-and-context-a7f8e9f158af
  var [state, dispatch] = React.useReducer(watchHistoryReducer, initialState);
  return (
    <WatchHistoryStateContext.Provider value={state}>
      <WatchHistoryDispatchContext.Provider value={dispatch}>
        {children}
      </WatchHistoryDispatchContext.Provider>
    </WatchHistoryStateContext.Provider>
  );
}

function useWatchHistoryState () {
  const context = React.useContext(WatchHistoryStateContext);
  if (context === undefined) {
    throw new Error(
      "useWatchHistoryState must be used within a WatchHistoryProvider",
    );
  }
  return context;
}

function useWatchHistoryDispatch () {
  const context = React.useContext(WatchHistoryDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useWatchHistoryDispatch must be used within a WatchHistoryProvider",
    );
  }
  return context;
}

export { WatchHistoryProvider, useWatchHistoryState, useWatchHistoryDispatch };
