import { format } from "date-fns";
import Cookies from "js-cookie";
import isDate from "lodash/isDate";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { staticUrl, apiUrl } from "./const";

function getVideoUrl (videoInfoState, videoType, trackIndex) {
  const videoUrl =
    videoType === "movie"
      ? get(videoInfoState, `play.movie.${trackIndex}.url`)
      : get(videoInfoState, `play.trailer.${trackIndex}.url`);
  return videoUrl;
}

function getToken () {
  return localStorage.getItem("id_token") || Cookies.get("id_token");
}

function setToken (token) {
  Cookies.set("id_token", token, {
    expires: 365,
  });
  return localStorage.setItem("id_token", token);
}

function resetToken () {
  localStorage.removeItem("id_token");
  Cookies.remove("id_token");
}

function setPreviousPage () {
  const path = window.location.pathname;
  const search = window.location.search;
  if (!path.match(/login/)) {
    Cookies.set("previous_path", `${path}${search}`, {
      expires: 1,
    });
  }
}

function getPreviousPage () {
  return Cookies.get("previous_path");
}

// TODO: FIXME
// if using HyPorxy, dev-backend.visionmedia.com.tw or backend.visionmedia.com.tw would be replaced with proxy url
function getDefaultAPIBase () {
  if (window.location.hostname.match(/visionmedia.com.tw|localhost/)) {
    return apiUrl;
  }
  // keep original hostname to avoid HyProxy replace it.
  return "";
}

function getAPIURL () {
  return localStorage.getItem("api_url") || Cookies.get("api_url");
}

// if hostname doesn't belong to proxy domain then skip setting.
function setAPIURL (url) {
  // EZProxy hardcode
  if (
    window.location.hostname == "nchu-app-visionmedia-com-tw.ap.lib.nchu.edu.tw"
  ) {
    console.log(`force api url for ezproxy`);
    url = "https://nchu-app-visionmedia-com-tw.ap.lib.nchu.edu.tw";
  }

  if (
    window.location.hostname.match(
      /app([0-9]+)?\.visionmedia\.com\.tw$|www\.visionmedia\.com\.tw$/,
    )
  ) {
    console.log(`skip setting api url because it's not a proxy domain.`);
    return;
  }

  Cookies.set("api_url", url, {
    expires: 365,
  });
  return localStorage.setItem("api_url", url);
}

function setHostname () {
  Cookies.set("hostname", window.location.hostname, {
    expires: 365,
  });
  return localStorage.setItem("hostname", window.location.hostname);
}

function getPreferQuality () {
  return Cookies.get("preferQuality");
}

function setPreferQuality (quality) {
  return Cookies.set("preferQuality", quality, {
    expires: 365,
    secure: true,
  });
}

function resetPreferQuality () {
  return Cookies.remove("preferQuality");
}

function isFirstEnterIPAutoLogin () {
  return !!Cookies.get("disableFirstEnterIPAutoLogin");
}

function enableFirstEnterIPAutoLogin () {
  Cookies.remove("disableFirstEnterIPAutoLogin");
}

function disableFirstEnterIPAutoLogin () {
  const coolDownDuration = new Date(new Date().getTime() + 10 * 1000);
  return Cookies.set("disableFirstEnterIPAutoLogin", "true", {
    expires: coolDownDuration,
    secure: true,
  });
}

function getReleaseQuotaQueue () {
  const releaseQuotaQueue = localStorage.getItem("releaseQuotaQueue") || "";
  return releaseQuotaQueue.split(",");
}

function delayReleaseQuota () {
  const releaseQuotaQueue = getReleaseQuotaQueue();
  releaseQuotaQueue.push(getTabID());
  return localStorage.setItem(
    "releaseQuotaQueue",
    `${releaseQuotaQueue.join(",")}`,
  );
}

function formatDate (dt, sep = "/", short = true) {
  const fmt = short ? `yyyy${sep}MM${sep}dd` : `yyyy${sep}MM${sep}dd HH:mm:ss`;
  if (isDate(dt)) {
    return format(dt, fmt);
  }
  return dt && format(new Date(dt), fmt);
}

function isValidDate (dateStr) {
  const datePattern = /^[0-9]{4}(-|\/)[0-9]{2}(-|\/)[0-9]{2}$/;
  if (!datePattern.test(dateStr)) {
    return false;
  }

  dateStr = dateStr.replace("/", "-");

  // Parse the date parts to integers
  const parts = dateStr.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

function getUnixTs () {
  return new Date().getTime();
}

function makeID (length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function defineTabID () {
  if (!sessionStorage) return;

  let pageTabID = sessionStorage.getItem("tabID");
  if (pageTabID === null) {
    pageTabID = makeID(5);
    sessionStorage.setItem("tabID", pageTabID);
  }
  return pageTabID;
}

function getTabID () {
  const pageTabID = sessionStorage.getItem("tabID");
  if (pageTabID === null) {
    return defineTabID();
  }

  return pageTabID;
}

function getMovieTime (videoId) {
  if (!localStorage) return 0;
  return Number.parseInt(localStorage.getItem(`time:vid:${videoId}`)) || 0;
}

function saveMovieTime (videoId, time) {
  if (!localStorage) return;
  return localStorage.setItem(`time:vid:${videoId}`, Number.parseInt(time));
}

function deleteMovieTime (videoId) {
  localStorage.removeItem(`time:vid:${videoId}`);
}

function isIE () {
  return (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    navigator.appVersion.indexOf("Trident/") > -1
  );
}

function isSmallScreen () {
  return window.innerWidth <= 768;
}

function getThumbnailUrl ({ url, ext, size }) {
  let sizes;
  if (size) {
    sizes = `${size}_${size}`;
  } else {
    sizes = "400_400";
  }
  const assetUrl = `${staticUrl}/${url
    .replace(/^\//, "")
    .replace("raw", sizes)}`;
  if (ext) {
    return encodeURI(assetUrl.replace(/\.\w+$/, `.${ext}`));
  }
  return encodeURI(assetUrl);
}

function getOptimizedImgUrl ({ url, ext }) {
  const sizes = "raw";
  const assetUrl = `${staticUrl}/${url
    .replace(/^\//, "")
    .replace("raw", sizes)}`;
  return encodeURI(assetUrl);
}

// https://gist.github.com/jwilson8767/db379026efcbd932f64382db4b02853e
function elementReady (selector) {
  return new Promise((resolve, reject) => {
    let el = document.querySelector(selector);
    if (el) {
      resolve(el);
      return;
    }
    new MutationObserver((mutationRecords, observer) => {
      // Query for elements matching the specified selector
      Array.from(document.querySelectorAll(selector)).forEach(element => {
        resolve(element);
        //Once we have resolved we don't need the observer anymore.
        observer.disconnect();
      });
    }).observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
}

export {
  getVideoUrl,
  getToken,
  setToken,
  resetToken,
  setPreviousPage,
  getPreviousPage,
  getDefaultAPIBase,
  getAPIURL,
  setAPIURL,
  setHostname,
  getPreferQuality,
  setPreferQuality,
  resetPreferQuality,
  formatDate,
  isValidDate,
  getUnixTs,
  defineTabID,
  getTabID,
  getMovieTime,
  saveMovieTime,
  deleteMovieTime,
  isIE,
  delayReleaseQuota,
  getThumbnailUrl,
  getOptimizedImgUrl,
  elementReady,
  isEmpty,
  isFirstEnterIPAutoLogin,
  enableFirstEnterIPAutoLogin,
  disableFirstEnterIPAutoLogin,
};
