import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { lazy } from "@loadable/component";
import { LastLocationProvider } from "react-router-last-location";
import Preconnect from "@shopify/react-preconnect";

// components
import Suspense from "../components/Suspense/Suspense";

// context
import { useUserState } from "../context/UserContext";

// others
import ScrollToTop from "./ScrollToTop";
import PageTabID from "./PageTabID";
import PlatformInfo from "./PlatformInfo";
import UserInfo from "./UserInfo";
import ReleaseQuota from "./ReleaseQuota";
import Track from "./Track";

import { apiUrl, staticUrl, trackUrl } from "../utils/const";
import { getPreviousPage } from "../utils/common";

// components
const Layout = lazy(() => import("./Layout"));
const MovieLayout = lazy(() => import("./MovieLayout"));
const PartnerLogin = lazy(() => import("../pages/partner-login"));
const PartnerIPAutoLogin = lazy(() => import("../pages/partner-ip-auto-login"));
const PartnerSSOLogin = lazy(() => import("../pages/partner-sso-login"));

export default function App () {
  // global
  const { isAuthenticated } = useUserState();

  const preconnectHosts = [
    apiUrl.replace("https://", ""),
    staticUrl.replace("https://", ""),
    trackUrl.replace("https://", ""),
    "storage.googleapis.com",
  ];

  return (
    <BrowserRouter>
      <Preconnect hosts={preconnectHosts} />
      <Track>
        <LastLocationProvider>
          <ScrollToTop />
          <PageTabID />
          <Suspense>
            <Switch>
              <Route exact path='/' render={() => <Redirect to='/video' />} />
              <Route exact path='/partner-login' component={PartnerLogin} />
              <Route
                exact
                path='/partner/ip-autologin'
                component={PartnerIPAutoLogin}
              />
              <Route
                exact
                path='/partner/sso-login'
                component={PartnerSSOLogin}
              />
              <PrivateRoute path='/app' component={Layout} />
              <PublicRoute path='/login' component={MovieLayout} />
              <Route component={MovieLayout} />
            </Switch>
          </Suspense>
          <PlatformInfo />
          <UserInfo />
          <ReleaseQuota />
        </LastLocationProvider>
      </Track>
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute ({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute ({ component, ...rest }) {
    let redirectPath = "/";
    try {
      redirectPath = getPreviousPage();
    } catch (e) {}

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: redirectPath,
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
