// Amplify deploy manually cannot setup custom env vars
const isDev = /dev\.|dev-|localhost|https:\/\/hyerm.ntl.edu.tw:3465/.test(window.location.href);
// TODO: FIXME
// Handle HyProxy replacement behavior
const apiUrl = `https://${isDev ? "dev-" : ""}back|end.visionmedia.com.tw`.replace('|', '');
const staticUrl = `https://${isDev ? "dev-" : ""}static.visionmedia.com.tw`;
const trackUrl = `https://${isDev ? "dev-" : ""}track.visionmedia.com.tw`;
const serviceLink = `${staticUrl}/html/service.html`;
const privacyLink = `${staticUrl}/html/privacy.html`;

const HCaptchaSiteKeyTest = "10000000-ffff-ffff-ffff-000000000001";
const HCaptchaSiteKey = "e433ad83-d531-4c1c-b048-07189a697a8f";

const GAID = "G-Q0JYQW2GR9";

const videoSaveTimeIntervalSec = 1;
const videoHeartBeatSec = 10;

export {
  isDev,
  apiUrl,
  staticUrl,
  trackUrl,
  serviceLink,
  privacyLink,
  HCaptchaSiteKeyTest,
  HCaptchaSiteKey,
  GAID,
  videoSaveTimeIntervalSec,
  videoHeartBeatSec,
};
