import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const start = () => {
  const tracesSampleRate =
    window.location.href.indexOf("debug=1") === -1 ? 0.001 : 1;
  Sentry.init({
    dsn:
      "https://de37ab2822df4541be944656153586a2@o187294.ingest.sentry.io/5612949",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate,
  });
};

export default { start };
