import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useUserState } from "../context/UserContext";
import { setPreviousPage } from "../utils/common";
import tracker from "../utils/tracker";

const Location = props => {
  const location = useLocation();
  const { trackPageView, pushInstruction } = useMatomo();
  const { userInfo } = useUserState();

  useEffect(() => {
    //You can also check for page redirect logic or going back/forward from the browser logic here
    //Check if pathname is not changing dont track the view
    //So that you dont end up tracking the same view again and again
    if (userInfo.id) {
      pushInstruction("setUserId", userInfo.id);
      tracker.GA.setUserID(userInfo.id);
    }
    setPreviousPage();
    trackPageView();
    tracker.GA.trackPageView();
  }, [location]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Location;
