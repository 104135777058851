import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import isEmpty from "lodash/isEmpty";
import {
  useUserDispatch,
  useUserState,
  loginIPUser,
} from "../context/UserContext";

import { staticUrl } from "../utils/const";
import { setHostname, isFirstEnterIPAutoLogin } from "../utils/common";
import { getPlatformInfo } from "../clientAPI";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { setBaseUrl, setDefaultBaseUrl } from "../axios";

function getFaviconEl () {
  return document.getElementById("favicon");
}

function PlatformInfo () {
  const history = useHistory();
  const userDispatch = useUserDispatch();
  const { platform, isAuthenticated } = useUserState();
  const { trackEvent, pushInstruction } = useMatomo();
  const [dummyState, setDummyState] = useState(false);

  useEffect(() => {
    setHostname();
    getPlatformInfo()
      .then(res => {
        userDispatch({
          type: "SET_LOGIN_URL",
          payload: { loginUrl: res.login_url || "" },
        });
        userDispatch({
          type: "SET_PLATFORM_INFO",
          payload: res,
        });
        if (res.api_url) {
          setBaseUrl(res.api_url);
        } else {
          setDefaultBaseUrl();
        }
        return res;
      })
      .then(platform => {
        if (
          window.location.pathname.match(/login/) !== null ||
          isAuthenticated ||
          platform.login_option_ip === false ||
          isFirstEnterIPAutoLogin()
        ) {
          return;
        }
        loginIPUser({
          dispatch: userDispatch,
          setIsLoading: setDummyState,
          trackEvent,
          pushInstruction,
          setIPLoginError: setDummyState,
          history,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(platform)) {
      const favicon = getFaviconEl();
      favicon.href = `${staticUrl}/${platform.favicon.replace(/^\//, "")}`;
      document.title = platform.cleaned_nickname || platform.nickname;
    }
  }, [platform]);

  return null;
}

export default PlatformInfo;
