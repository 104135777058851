import { useEffect, useState } from "react";
import { useLastLocation } from "react-router-last-location";

import { getToken } from "../utils/common";
import { releaseQuota } from "../clientAPI";

const ReleaseQuota = () => {
  const lastLocation = useLastLocation();
  const [preLocation, setPrevLocation] = useState(lastLocation);
  const isMoviePage = location =>
    location && location.pathname.indexOf("/video/detail/movie") !== -1;
  useEffect(() => {
    if (isMoviePage(window.location)) {
      return;
    }
    if (!getToken()) {
      return;
    }
    if (preLocation === null && lastLocation === null) {
      releaseQuota();
    } else if (!isMoviePage(preLocation) && isMoviePage(lastLocation)) {
      releaseQuota();
    }
    setPrevLocation(lastLocation);
  }, [lastLocation]);

  return null;
};

export default ReleaseQuota;
