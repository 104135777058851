import axios from "axios";
import Cookies from "js-cookie";
import queryString from "query-string";
import {
  getToken,
  resetToken,
  getDefaultAPIBase,
  getAPIURL,
  setAPIURL,
} from "./utils/common";

const timeout = 12000;

const axiosInstance = axios.create({
  withCredentials: true,
  timeout: timeout,
});
axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use(async function (config) {
  const { force_platform } = queryString.parse(window.location.search);
  if (force_platform) {
    config.params = { ...config.params, force_platform };
  }

  const token = getToken() || "";
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const apiUrl = getBaseUrl();
  axiosInstance.defaults.baseURL = apiUrl;

  config.baseURL = apiUrl;
  axiosInstance.defaults.withCredentials = true;
  config.withCredentials = true;

  config.headers['original-origin'] = window.location.origin;

  return config;
}, null);

// https://github.com/axios/axios/issues/2315#issuecomment-568679445
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.response &&
      (error.response.status === 401 ||
        (error.response.status === 404 &&
          error.response.data.detail === "User not found"))
    ) {
      resetToken();
    }
    return Promise.reject(error);
  },
);

export const getBaseUrl = () => {
  return getAPIURL() || getDefaultAPIBase();
};

export const setDefaultBaseUrl = () => {
  const url = getDefaultAPIBase();
  setAPIURL(url);
  axiosInstance.defaults.baseURL = url;
  return url;
};

export const setBaseUrl = url => {
  if (url) {
    setAPIURL(url);
    axiosInstance.defaults.baseURL = url;
    return url;
  }
};

export default axiosInstance;
