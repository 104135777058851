import { useEffect } from "react";

import { defineTabID } from "../utils/common";
import { releaseQuotaByBeacon } from "../clientAPI";

function PageTabID () {
  useEffect(() => {
    const tabId = defineTabID();

    // https://requestmetrics.com/building/using-the-beacon-api
    (function client () {
      if (!navigator.sendBeacon) {
        return;
      }

      function onUnload () {
        if (onUnload._hasUnloaded) {
          return;
        }
        onUnload._hasUnloaded = true;
        releaseQuotaByBeacon(tabId);
      }

      window.addEventListener("unload", onUnload);
      window.addEventListener("pagehide", onUnload);
    })();
  }, []);

  return null;
}

export default PageTabID;
