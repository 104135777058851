import GA4React from "ga-4-react";
import { createInstance } from "@datapunt/matomo-tracker-react";
import { isDev, trackUrl, GAID } from "./const";

const create = () => {
  const instance = createInstance({
    urlBase: trackUrl,
    siteId: 1,
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
      // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 10, // optional, default value: `15
    },
    linkTracking: true, // optional, default value: true
    configurations: {
      // optional, default value: {}
      // any valid matomo configuration, all below are optional
      setSecureCookie: true,
    },
  });
  return instance;
};

const GA = {
  instance: async () => {
    if (isDev) return;
    if (GA4React.isInitialized()) return GA4React.getGA4React();
    const ga4react = new GA4React(GAID);
    await ga4react.initialize();
    return GA4React.getGA4React();
  },
  trackPageView: async () => {
    const ga4react = await GA.instance();
    if (ga4react) {
      ga4react.pageview();
    }
  },
  setUserID: async userId => {
    const ga4react = await GA.instance();
    if (ga4react) {
      ga4react.gtag("config", "MEASUREMENT_ID", {
        user_id: userId,
      });
    }
  },
};

const login = ({
  trackEvent,
  pushInstruction,
  method = "password",
  isPartner = false,
  userId = null,
} = {}) => {
  if (userId) {
    pushInstruction("setUserId", userId);
  }
  trackEvent({
    category: "user",
    action: "login",
    name: userId,
    value: 1,
    customDimensions: [
      {
        id: 1,
        value: method,
      },
    ],
  });
};

// Partner login means different users would use the same device login.
// So we design the session reset mechanisan to identify them.
const partnerLogin = ({ method, userId, trackEvent, pushInstruction }) => {
  resetSession(pushInstruction);
  login({ trackEvent, pushInstruction, method, isPartner: true, userId });
};

const setUserDetail = ({
  pushInstruction,
  userId,
  userName,
  userType,
  // platformId,
  // platformName,
}) => {
  pushInstruction("setUserId", userId);
};

const resetSession = pushInstruction => {
  pushInstruction("resetUserId");
  pushInstruction("appendToTrackingUrl", "new_visit=1");
  pushInstruction("trackPageView");
  pushInstruction("appendToTrackingUrl", "");
};

const viewedVideoPage = ({
  trackEvent,
  pushInstruction,
  videoType,
  videoId,
  tabId,
}) => {
  trackEvent({
    category: "user",
    action: "viewed_video_page",
    name: videoId,
    value: 1,
    customDimensions: [
      {
        id: 5,
        value: videoType,
      },
      {
        id: 3,
        value: tabId,
      },
    ],
  });
  pushInstruction("deleteCustomDimension", 3);
  pushInstruction("deleteCustomDimension", 5);
};

const recordNoQuota = ({ trackEvent, videoId, videoType, quotaCnt }) => {
  trackEvent({
    category: "user",
    action: "no_quota",
    name: videoId,
    value: quotaCnt,
    customDimensions: [
      {
        id: 5,
        value: videoType,
      },
    ],
  });
};

const watchedVideo = ({
  trackEvent,
  pushInstruction,
  videoType,
  videoId,
  tabId,
  spent = 5,
  currentTime,
  duration,
}) => {
  trackEvent({
    category: "video",
    action: "watched",
    name: videoId,
    value: spent,
    customDimensions: [
      {
        id: 5,
        value: videoType,
      },
      {
        id: 3,
        value: tabId,
      },
      {
        id: 2,
        value: currentTime,
      },
      {
        id: 4,
        value: duration,
      },
    ],
  });

  pushInstruction("deleteCustomDimension", 2);
  pushInstruction("deleteCustomDimension", 3);
  pushInstruction("deleteCustomDimension", 4);
  pushInstruction("deleteCustomDimension", 5);
};

const playedVideo = ({
  trackEvent,
  pushInstruction,
  videoType,
  videoId,
  tabId,
}) => {
  trackEvent({
    category: "video",
    action: "played",
    name: videoId,
    value: 1,
    customDimensions: [
      {
        id: 5,
        value: videoType,
      },
      {
        id: 3,
        value: tabId,
      },
    ],
  });
  pushInstruction("deleteCustomDimension", 3);
  pushInstruction("deleteCustomDimension", 5);
};

const pausedVideo = ({
  trackEvent,
  pushInstruction,
  videoType,
  videoId,
  tabId,
  currentTime,
}) => {
  trackEvent({
    category: "video",
    action: "paused",
    name: videoId,
    value: currentTime,
    customDimensions: [
      {
        id: 5,
        value: videoType,
      },
      {
        id: 3,
        value: tabId,
      },
    ],
  });
  pushInstruction("deleteCustomDimension", 3);
  pushInstruction("deleteCustomDimension", 5);
};

const resumedVideo = ({
  trackEvent,
  pushInstruction,
  videoType,
  videoId,
  tabId,
  currentTime,
}) => {
  trackEvent({
    category: "video",
    action: "resumed",
    name: videoId,
    value: currentTime,
    customDimensions: [
      {
        id: 5,
        value: videoType,
      },
      {
        id: 3,
        value: tabId,
      },
    ],
  });
  pushInstruction("deleteCustomDimension", 3);
  pushInstruction("deleteCustomDimension", 5);
};

const seekedVideo = ({
  trackEvent,
  pushInstruction,
  videoType,
  videoId,
  tabId,
  currentTime,
}) => {
  trackEvent({
    category: "video",
    action: "seeked",
    name: videoId,
    value: currentTime,
    customDimensions: [
      {
        id: 5,
        value: videoType,
      },
      {
        id: 3,
        value: tabId,
      },
    ],
  });
  pushInstruction("deleteCustomDimension", 3);
  pushInstruction("deleteCustomDimension", 5);
};

export default {
  create,
  login,
  partnerLogin,
  resetSession,
  setUserDetail,
  viewedVideoPage,
  watchedVideo,
  playedVideo,
  pausedVideo,
  resumedVideo,
  seekedVideo,
  recordNoQuota,
  GA,
};
